import React from 'react';
import s from './PreviewTabTitle.module.scss'
import {isTablet} from "../../App/App";

const PreviewTabTitle = ({title = 'def'}) => {

    if(isTablet) {
        return (
            <div className={s.TabTitleTablet}>
                <div />
                <div>
                    <div>
                        <span>{title}</span>
                        <div />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={s.TabTitleDesktop}>
            <div className={s.line} />
            <div className={s.title}>
                <div className={s.titleContent}>
                    <span>{title}</span>
                    <div />
                </div>
            </div>
        </div>
    );
};

export default PreviewTabTitle;