import React from 'react';
import s from './Header.module.scss'
import {ReactComponent as YRIcon} from "../../assets/icons/YR.svg";
import {ReactComponent as FacebookIcon} from "../../assets/icons/facebookM.svg";
import {ReactComponent as XIcon} from "../../assets/icons/xM.svg";
import {ReactComponent as InstaIcon} from "../../assets/icons/instaM.svg";
import {ReactComponent as InIcon} from "../../assets/icons/inM.svg";
import {TABS} from "../../App/App";
import {facebook, twitter, instagram, linkedin} from "../../utils/consts";

const Header = ({onSelectTab}) => {
    return (
        <div className={s.Header}>
                <YRIcon onClick={onSelectTab(TABS.MAIN, true)}/>
            <span className={s.socialMedia}>
                <a rel="noreferrer" target="_blank" href={facebook}><FacebookIcon /></a>
                <a rel="noreferrer" target="_blank" href={twitter}><XIcon /></a>
                <a rel="noreferrer" target="_blank" href={instagram}><InstaIcon /></a>
                <a rel="noreferrer" target="_blank" href={linkedin}><InIcon /></a>
            </span>
        </div>
    );
};

export default Header;