import News1Image from "../../assets/images/News-1.png";
import News5Image from "../../assets/images/News-5.jpeg";
import News6Image from "../../assets/images/News-6-a.png";
import News7Image from "../../assets/images/News-7.jpeg";
import News2Image from "../../assets/images/News-2.png";
import News4Image from "../../assets/images/News-4.jpeg";
import News3Image from "../../assets/images/News-3-a.png";

export const linksData = [
    {
        title: 'Israel’s tourism minister channels James Bond in promotional video',
        url: 'https://www.timesofisrael.com/israels-tourism-minister-channels-james-bond-in-promotional-video/',
        photoUrl: 'https://static.timesofisrael.com/www/uploads/2022/10/2022-10-16-1-e1665910004285.png?_gl=1*wbaf7a*_ga*NjEyMjc2NDI3LjE3MDA4MjcxODc.*_ga_RJR2XWQR34*MTcwMTA4MjEwMC4xLjEuMTcwMTA4MjcxOS4wLjAuMA',
        photo: News1Image,
        source: 'timesofisrael.com',
    },
    {
        title: "El Al Israel Airlines will launch direct flights to Japan from March 2023",
        url: "https://detaly.co.il/s-marta-2023-goda-el-al-zapustit-pryamye-rejsy-v-yaponiyu/",
        photoUrl: "https://detaly.co.il/wp-content/uploads/2022/09/adfadfa.jpeg",
        photo: News5Image,
        source: 'detaly.co.il',
    },
    {
        title: "Yoel Razvozov met with the Chinese Ambassador to Israel",
        url: "https://mignews.com/news/politic/razvozov-vstretilsya-s-poslom-kitaya-v-izraile.html",
        photoUrl: "https://mignews.com/media/cache/23/ae/23aef8700995d779a32342c96921db43.jpg",
        photo: News6Image,
        source: 'mignews.com',
    },
    {
        title: "Israeli Tourism Minister Yoel Razvozov : Direct flights from Almaty to Tel Aviv will be overcrowded",
        url: "https://forbes.kz/process/ministr_turizma_izrailya_pryamyie_reysyi_almatyi_tel-aviv_budut_perepolnenyi?",
        photoUrl: "https://static.forbes.kz/img/articles/c2562bc2f016e9753f8b70c6e19688dc-small.JPG",
        photo: News7Image,
        source: 'forbes.kz',
    },
    {
        title: "Israel minister of tourism Yoel Razvozov on the country's ambitious tourism goals",
        url: "https://www.travelweekly.com/On-The-Record/Yoel-Razvozov-Israel-Minister-of-Tourism",
        photoUrl: "https://ik.imgkit.net/3vlqs5axxjf/TW/ik-seo/uploadedImages/Art/2022/0711/T0711YOELRAZVOZOV_C/Yoel-Razvozov.jpg?tr=w-500%2Cfo-auto",
        photo: News2Image,
        source: 'travelweekly.com',
    },
    {
        title: "Ministers of Tourism of Uzbekistan and Israel opened Tashkent Travel Mart 2022",
        url: "https://www.uzdaily.uz/en/post/73042",
        photoUrl: "https://www.uzdaily.uz/storage/img/2022/05/262A97571.jpg",
        photo: News4Image,
        source: 'uzdaily.uz',
    },
    {
        title: "Israel and the Arab Emirates signed a tourism cooperation agreement",
        url: "https://www.newsru.co.il/mideast/09feb2022/uae_008.html",
        photoUrl: "https://images.newsru.co.il/m/201/53/2015305.jpg",
        photo: News3Image,
        source: 'newsru.co.il',
    },
]
