import {useEffect, useState} from "react";
import s from './App.module.scss'
import Header from "../components/Header/Header";
import LeftContent from "../tabs/LeftContent/LeftContent";
import AboutMe from "../tabs/AboutMe/AboutMe";
import BusinessDevAndStr from "../tabs/BusinessDevAndStr/Business";
import News from "../tabs/News/News";
import Contact from "../tabs/Contact/Contact";
import Main from "../tabs/Main/Main";

const DEVICES = {
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE',
}

export const TABS = {
    MAIN: 'main',
    ABOUT_ME: 'aboutMe',
    BUSINESS: 'business',
    NEWS: 'news',
    CONTACT: 'contact'
}

export const isDesktop = window.innerWidth > 1133
export const isTablet = !isDesktop
// export const isMobile = window.innerWidth < 431
export const isIphoneSEVertical = window.innerWidth < 376 && window.innerHeight < 668
export const isIphoneSEHorizontal = window.innerHeight < 376 && window.innerWidth < 668

export const isIphoneProMaxVertical = window.innerWidth < 431 && window.innerHeight < 933
export const isIphoneProMaxHorizontal = window.innerHeight < 431 && window.innerWidth < 933

export const isMobile = isIphoneSEVertical || isIphoneSEHorizontal || isIphoneProMaxVertical || isIphoneProMaxHorizontal

// console.log(isIphoneProMaxVertical, isIphoneProMaxHorizontal)
// console.log(window.innerHeight, window.innerWidth)
console.log(isMobile, window.innerWidth , window.innerHeight)

function App() {
    console.log('changed')

    const [isLeftFullScreen, setIsLeftFullScreen] = useState(true)
    const [selectedTab, setSelectedTab] = useState(TABS.MAIN)

    //TODO: detect device position to change UI

    // const [device, setDevice] = useState(true)

    // useEffect(() => {
    //     console.log('changed')
    //     if(isDesktop) {
    //         setDevice(DEVICES.DESKTOP)
    //     }
    //
    //     if (isTablet) {
    //         setDevice(DEVICES.TABLET)
    //     }
    //
    //     if (isMobile) {
    //         setDevice(DEVICES.MOBILE)
    //     }
    //
    // }, [window.innerWidth])

    const onSelectTab = (tabName, isLeftFullScreen = false) => () => {
        setSelectedTab(tabName)
        setIsLeftFullScreen(isLeftFullScreen)
    }

    const isShowHeader = isDesktop || isTablet && selectedTab === TABS.MAIN && !isMobile
    const isShowCloseIcon = isTablet && selectedTab !== TABS.MAIN

    return (
        <div className={`${isTablet && s.appTablet} ${isDesktop && s.AppDesktop}`}>
            {isShowHeader && <Header onSelectTab={onSelectTab} />}

            {isDesktop && <LeftContent
                tabName={TABS.MAIN}
                selectedTab={selectedTab}
                isLeftFullScreen={isLeftFullScreen}
            />}

            {isTablet || isMobile ? <Main isTabSelected={selectedTab === TABS.MAIN}/> : null}

            <div className={`${s.tabs} ${isDesktop && s.tabsDesktop}`}>
                <AboutMe
                    isShowCloseIcon={isShowCloseIcon}
                    isTabSelected={selectedTab === TABS.ABOUT_ME}
                    selectedTab={selectedTab}
                    tabName={TABS.ABOUT_ME}
                    onSelectTab={onSelectTab}
                />
                <BusinessDevAndStr
                    isShowCloseIcon={isShowCloseIcon}
                    isTabSelected={selectedTab === TABS.BUSINESS}
                    selectedTab={selectedTab}
                    tabName={TABS.BUSINESS}
                    onSelectTab={onSelectTab}
                />
                <News
                    isShowCloseIcon={isShowCloseIcon}
                    isTabSelected={selectedTab === TABS.NEWS}
                    selectedTab={selectedTab}
                    tabName={TABS.NEWS}
                    onSelectTab={onSelectTab}
                />
                <Contact
                    isShowCloseIcon={isShowCloseIcon}
                    isTabSelected={selectedTab === TABS.CONTACT}
                    selectedTab={selectedTab}
                    tabName={TABS.CONTACT}
                    onSelectTab={onSelectTab}
                />
            </div>
        </div>
    );
}

export default App;
