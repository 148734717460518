import React from 'react';
import s from './Business.module.scss'
import PreviewTabTitle from "../../components/PreviewTabTitle/PreviewTabTitle";
import {Scrollbars} from "react-custom-scrollbars-2";
import {isDesktop, isMobile, TABS} from "../../App/App";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";

const BusinessContent = () => (
    <div className={s.content}>
        <div className={s.tabTitle}>
            Business development and strategy
        </div>
        <div className={s.text}>
            On the way to a modern, competitive business, I define my vision as progressive and
            strategically
            oriented. I believe that successful business development lies in the ability to recognize and
            adapt
            to
            the most significant trends in the modern world.
        </div>

        <div className={s.title}>Innovations and Technologies</div>
        <div className={s.text}>
            I am committed to implementing cutting-edge technologies and innovations that help
            us identify new opportunities and increase efficiency in a constantly changing environment.
        </div>

        <div className={s.title}>Strategic Partnerships</div>
        <div className={s.text}>
            Our development strategy is based on entering into strategic partnerships that help us combine
            our
            strengths and expand opportunities for joint success.
        </div>

        <div className={s.title}>Global Presence</div>
        <div className={s.text}>
            My ambition is to be a player in the global market, providing our customers and partners with
            access
            to
            global opportunities and developing our business in line with the requirements of the global
            economy.
        </div>

        <div className={s.title}>Sustainability and Value</div>
        <div className={s.text}>
            My development strategy is based on sustainability and mutually beneficial relationships. I
            believe
            in
            long-term success built on ethics, responsibility and trust.
        </div>

        <div className={s.title}>Adaptation to Change</div>
        <div className={s.text}>
            In today's world, where change is the new normal, I am ready and actively adapt to changes in
            the
            business environment by implementing flexible and effective strategies.
        </div>
        <div className={s.copyright}>
            @ 2023. All Rights Reserved
        </div>
    </div>
)

const BusinessDesktop = ({isTabSelected, tabName, onSelectTab}) => {
    if (!isTabSelected) {
        return (
            <div
                onClick={onSelectTab(tabName)}
                className={s.BusinessDesktop}>
                <PreviewTabTitle title={'Business development and strategy'} />
            </div>
        )
    }

    return (
        <div className={`${s.BusinessDesktop} ${isTabSelected && s.selectedDesktop} ${s.tableDesktop}`}>
            <Scrollbars className={s.scrollBar}>
                <BusinessContent />
            </Scrollbars>
        </div>
    );
};

const BusinessTablet = ({isTabSelected, tabName, onSelectTab, isShowCloseIcon}) => {
    if (!isTabSelected) {
        return (
            <div
                onClick={onSelectTab(tabName)}
                className={s.BusinessTablet}>
                <PreviewTabTitle title={isMobile ? <span>
                    <span>Business development and </span>
                    <br/>
                    <span>strategy</span>
                </span>: 'Business development and strategy'} />
            </div>
        )
    }

    return (
        <div className={`${s.BusinessTablet} ${isTabSelected && s.selectedTablet} ${s.tableTablet}`}>
            {isShowCloseIcon && <CloseIcon className={s.closeIcon} onClick={onSelectTab(TABS.MAIN)} />}
            <Scrollbars className={s.scrollBar}>
                <BusinessContent />
            </Scrollbars>
        </div>
    );
};

const Business = (props) => {
    if(props.selectedTab !== TABS.MAIN && !props.isTabSelected && !isDesktop) return null

    return isDesktop
        ? <BusinessDesktop {...props} />
        : <BusinessTablet {...props} />
}

export default Business