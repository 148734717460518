import React, {useState} from 'react';
import s from './Contact.module.scss'
import PreviewTabTitle from "../../components/PreviewTabTitle/PreviewTabTitle";
import emailjs from "@emailjs/browser";
import {ReactComponent as SuccessIcon} from "../../assets/icons/success.svg";
import {isDesktop, isMobile, isTablet, TABS} from "../../App/App";
import {ReactComponent as FacebookIcon} from "../../assets/icons/facebookM.svg";
import {ReactComponent as XIcon} from "../../assets/icons/xM.svg";
import {ReactComponent as InstaIcon} from "../../assets/icons/instaM.svg";
import {ReactComponent as InIcon} from "../../assets/icons/inM.svg";
import {facebook, instagram, linkedin, twitter} from "../../utils/consts";
import {Scrollbars} from "react-custom-scrollbars-2";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";

const ContactContent = () => {
    const [isSent, setIsSent] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const isEmpty = !name || !email || !message

    const onCLear = () => {
        setName('')
        setEmail('')
        setMessage('')
    }

    const onSend = () => {
        if (!isEmpty) {
            emailjs
                .send(
                    "service_5p4s71q",
                    "template_18v3qf7",
                    {name, email, message,},
                    "h05Pn7544EdPq_Jdj"
                )
                .then(
                    (result) => {
                        setIsSent(true);
                        onCLear();
                        setTimeout(() => {
                            setIsSent(false)
                        }, 5000)
                    },
                    (error) => {
                        console.log(error.text);
                    });
        }
    };

    const onChangeName = (e) => setName(e.target.value)
    const onChangeEmail = (e) => setEmail(e.target.value)
    const onChangeMessage = (e) => setMessage(e.target.value)

    return (
        <div className={s.content}>
            <div className={s.title}>
                Contact
            </div>
            <div className={s.text}>
                My goal is to provide ongoing business development and strategic guidance to my clients and partners that creates a future where innovation, sustainability and success are integral to our overall approach to building a great and impactful business.
            </div>
            {isTablet && (<div className={s.links}>
                <a rel="noreferrer" target="_blank" href={facebook}><FacebookIcon /></a>
                <a rel="noreferrer" target="_blank" href={twitter}><XIcon /></a>
                <a rel="noreferrer" target="_blank" href={linkedin}><InIcon /></a>
                <a rel="noreferrer" target="_blank" href={instagram}><InstaIcon /></a>
            </div>)}
            <div className={s.messageTitle}>
                Send me a message
            </div>
            <form>
                <label>Name</label>
                <input value={name} onChange={onChangeName} type="text" />
                <label htmlFor="email">Email</label>
                <input value={email} onChange={onChangeEmail} type="email" />
                <label>Message text</label>
                <textarea value={message} onChange={onChangeMessage} type="text" />
            </form>
            <div className={s.buttons}>
                <button
                    onClick={onSend}
                    className={`${s.approve} ${isEmpty && s.sent}`}
                >
                    Send
                </button>
                <button onClick={onCLear} className={s.clear}>
                    Clear all
                </button>
            </div>
            {isSent && (
                <span className={s.success}>
                    <SuccessIcon />
                    <span>
                        Thank you, the message was successfully sent, wait for the feedback
                    </span>
                </span>
            )}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className={s.copyright}>
                @ 2023. All Rights Reserved
            </div>
        </div>
    )
}

const ContactDesktop = (props) => {
    const {isTabSelected, tabName, onSelectTab} = props
    if (!isTabSelected) {
        return (
            <div
                onClick={onSelectTab(tabName, true)}
                className={s.ContactDesktop}>
                <PreviewTabTitle title={'Contact'} />
            </div>
        )
    }

    return (
        <div className={`${s.ContactDesktop} ${props.isTabSelected && s.selectedDesktop}`}>
            <Scrollbars>
                <ContactContent {...props} />
            </Scrollbars>
        </div>
    );
};

const ContactTablet = (props) => {
    const {isTabSelected, tabName, onSelectTab, isShowCloseIcon} = props

    if (!isTabSelected) {
        return (
            <div
                onClick={onSelectTab(tabName, true)}
                className={s.ContactTablet}>
                <PreviewTabTitle title={'Contact'} />
            </div>
        )
    }
    return (
        <div className={`${s.ContactTablet} ${props.isTabSelected && s.selectedTablet}`}>
            {isShowCloseIcon && <CloseIcon className={s.closeIcon} onClick={onSelectTab(TABS.MAIN)} />}
            <Scrollbars>
                <ContactContent {...props} />
            </Scrollbars>
        </div>
    );
};


const Contact = (props) => {
    if(props.selectedTab !== TABS.MAIN && !props.isTabSelected && !isDesktop) return null

    return isDesktop
        ? <ContactDesktop {...props} />
        : <ContactTablet {...props} />
}

export default Contact;