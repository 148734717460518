import React from 'react';
import s from './LeftContent.module.scss'
import {TABS} from "../../App/App";
import MainImage from '../../assets/images/Main-image.png'
import ContactImage from '../../assets/images/Contact-image.png'
import {ReactComponent as TitleSvg} from '../../assets/images/Yoel_Razvozov.svg'


const LeftContent = ({isLeftFullScreen, selectedTab}) => {
    const isDesignWidth = window.innerWidth >= 1720

    return (
        <div className={`${s.LeftContent} ${isLeftFullScreen && s.open}`}>

            {/*MAIN tab*/}
            <div className={`${s.main} ${selectedTab ===TABS.MAIN && s.visible}`}>
                <div className={s.title}>
                    <TitleSvg />
                    <div className={`${s.subTitle} ${isDesignWidth ? s.bigScreen: s.smallScreen}`}>
                        <span>Strategic Business advisor,</span>
                        <span>Former Minister of Tourism of the</span>
                        <span>State of Israel, Member of</span>
                        <span>Parliament, Olympic Judo Athlete</span>
                    </div>
                </div>
                <img src={MainImage} alt="" />
            </div>

            {/*ABOUT ME tab*/}
            <div className={`${s.aboutMe} ${selectedTab ===TABS.ABOUT_ME && s.visible}`}>
                <div className={s.image} />
                <div className={s.subTitle}>
                    "I have not left politics, it will remain with me apparently
                    forever as I have devoted 15 years of my 43 years of life to
                    political activity. That I see the current move as a break.
                    Politics affects the lives of the citizens of our country, and I
                    am the father of two daughters and I care about the Israel
                    they will live in."
                </div>
            </div>

            {/*BUSINESS tab*/}
            <div className={`${s.business} ${selectedTab ===TABS.BUSINESS && s.visible}`}>
                <div className={s.image} />
                <div className={s.subTitle}>
                    <span>"What am I here for?" - I am sure everyone has asked this</span>
                    <span>question, and more than once. Understanding one's role in</span>
                    <span>this world is the fundamental basis of every person's</span>
                    <span>existence.</span>
                </div>
            </div>

            {/*NEWS tab*/}
            <div className={`${s.news} ${selectedTab ===TABS.NEWS && s.visible}`}>
                <div className={s.image} />
                <div className={s.subTitle}>
                    <span>I'm open to the community.</span>
                    <span>I have a lot to say and talk about.</span>
                </div>
            </div>

            {/*CONTACT tab*/}
            <div className={`${s.contact} ${selectedTab ===TABS.CONTACT && s.visible}`}>
                {/*<div className={s.image} />*/}
                <img src={ContactImage} alt="" />
                <div className={s.subTitle}>
                    <span>I am looking forward to receiving your message.</span>
                    <span>Your enquiries are valuable to me.</span>
                    <span>Best regards,</span>
                    <br/>
                    <span>Yoel Razvozov</span>
                </div>
            </div>
        </div>
    );
};

export default LeftContent;