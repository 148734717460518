// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_Header__dTf11 {
  position: absolute;
  top: 23px;
  left: 22px;
  display: flex;
  flex-direction: row;
  gap: 33px;
  z-index: 2;
}
.Header_Header__dTf11 svg {
  cursor: pointer;
}
.Header_Header__dTf11 .Header_socialMedia__zTwEy {
  display: flex;
  gap: 16px;
}
.Header_Header__dTf11 .Header_socialMedia__zTwEy svg {
  height: 24px;
  width: 24px;
  fill: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,UAAA;AADF;AAGE;EACE,eAAA;AADJ;AAIE;EACE,aAAA;EACA,SAAA;AAFJ;AAII;EACE,YAAA;EACA,WAAA;EACA,UAAA;AAFN","sourcesContent":["@import '../../styles/variables';\n\n.Header {\n  position: absolute;\n  top: 23px;\n  left: 22px;\n  display: flex;\n  flex-direction: row;\n  gap: 33px;\n  z-index: 2;\n\n  svg {\n    cursor: pointer;\n  }\n\n  .socialMedia {\n    display: flex;\n    gap: 16px;\n\n    svg {\n      height: 24px;\n      width: 24px;\n      fill: #fff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `Header_Header__dTf11`,
	"socialMedia": `Header_socialMedia__zTwEy`
};
export default ___CSS_LOADER_EXPORT___;
