import React from 'react';
import s from './AboutMe.module.scss'
import PreviewTabTitle from "../../components/PreviewTabTitle/PreviewTabTitle";
import {education, featured, workHistory} from "./text";
import {Scrollbars} from "react-custom-scrollbars-2";
import {isDesktop, isMobile, isTablet, TABS} from "../../App/App";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";

const AboutMeContent = ({onSelectTab, isShowCloseIcon}) => (
    <div className={s.content}>
        {isShowCloseIcon && <CloseIcon className={s.closeIcon} onClick={onSelectTab(TABS.MAIN)} />}
        <div className={s.title}>About me</div>
        <div className={s.subTitle}>Proffessional summary</div>
        <div className={s.biography}>
            Born in 1980 in Birobidzhan, the Jewish Autonomy in the Soviet Union, I moved to Israel with my family in
            1991. In my military service, I held commanding positions and was at the rank of sergeant. After joining
            "Yesh Atid" party in 2013, as people say I quickly became one of the most recognizable Israeli politician by
            promoting the interests of immigrants, athletes and other sectors of society, as well as advancing Israel's
            economic relations with the CIS countries. During his tenure as the Minister of Tourism, I spearheaded
            tourism recovery in the postpandemic period and largely contributed to developing intranational tourism
            cooperation.
        </div>
        <div className={s.detailsWrapper}>
            <div className={s.detailsTitle}>
                WORK HISTORY
            </div>
            <div className={s.details}>
                {workHistory.map(item => (
                    <>
                        <div className={s.upperTitle}>{item.upperTitle}</div>
                        <div className={s.title}>{item.title}</div>
                        <div className={s.text}>{item.text}</div>
                    </>
                ))}
            </div>
        </div>
        <div className={s.detailsWrapper}>
            <div className={s.detailsTitle}>
                EDUCATION
            </div>
            <div className={s.details}>
                {education.map(item => (
                    <>
                        <div className={s.educationTitle}>{item.title}</div>
                        <div className={s.text}>{item.text}</div>
                    </>
                ))}
            </div>
        </div>
        <div className={s.detailsWrapper}>
            <div className={s.detailsTitle}>
                FEATURED
            </div>
            <div className={s.details}>
                {featured.map(item => (
                    <div>
                        <div className={s.featureTitle}>{item.title}</div>
                        <div className={s.link}>
                            <a target="_blank" href={item.text}>{item.text}</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className={s.copyright}>
            @ 2023. All Rights Reserved
        </div>
    </div>
)

const AboutMeDesktop = (props) => {
    const {isTabSelected, onSelectTab, tabName} = props
    if (!isTabSelected) {
        return (
            <div onClick={onSelectTab(tabName)} className={s.AboutMeDesktop}>
                <PreviewTabTitle title={'About me'} />
            </div>
        )
    }

    return (
        <div className={`${s.AboutMeDesktop} ${isTabSelected && s.selectedDesktop} ${s.tableDesktop}`}>
            <Scrollbars className={s.scrollBar}>
                <AboutMeContent {...props} />
            </Scrollbars>
        </div>
    );
}

const AboutMeTablet = (props) => {
    const {isTabSelected, tabName, onSelectTab} = props
    if (!isTabSelected) {
        return (
            <div onClick={onSelectTab(tabName)} className={s.AboutMeTablet}>
                <PreviewTabTitle title={'About me'} />
            </div>
        )
    }

    return (
        <div className={`${s.AboutMeTablet} ${isTabSelected && s.selectedTablet} ${s.tableTablet}`}>
            <Scrollbars className={s.scrollBar}>
                <AboutMeContent {...props} />
            </Scrollbars>
        </div>
    )
};

const AboutMe = (props) => {
    if (props.selectedTab !== TABS.MAIN && !props.isTabSelected && !isDesktop) return null

    return isDesktop
        ? <AboutMeDesktop {...props} />
        : <AboutMeTablet {...props} />
}

export default AboutMe