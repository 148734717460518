import React from 'react';
import {ReactComponent as TitleSvg} from '../../assets/images/Yoel_Razvozov.svg'
import s from './Main.module.scss'
import {
    isDesktop,
    isIphoneProMaxHorizontal,
    isIphoneProMaxVertical,
    isIphoneSEHorizontal,
    isIphoneSEVertical,
    isMobile, TABS
} from "../../App/App";

const MainTablet = () => {
    const isVertical = window.innerWidth < 1025 && window.innerHeight > 768

    return (
        <div className={s.MainTablet}>
            {isVertical
                ? <div className={s.vertical}>
                    <div className={s.text}>
                        <TitleSvg className={s.iconTablet} />
                        <div className={s.subTitle}>
                            Strategic Business advisor, Former Minister of Tourism of the State
                            of Israel, Member of Parliament,
                            Olympic Judo Athlete
                        </div>
                    </div>
                    <div className={s.photo} />
                </div>
                : <div className={s.horizont}>
                    <div className={s.text}>
                        <TitleSvg className={s.iconTablet} />
                        <div className={s.subTitle}>
                            Strategic Business advisor, Former Minister of Tourism of the State
                            of Israel, Member of Parliament,
                            Olympic Judo Athlete
                        </div>
                    </div>
                    <div className={s.photo} />
                </div>
            }
        </div>
    );
};

const MainMobile = () => {
    const isIphoveSE = isIphoneSEVertical || isIphoneSEHorizontal
    if (isIphoveSE) {
        if (isIphoneSEVertical) {
            return (
                <div className={s.MainMobileIphoneSEVertical}>
                    <TitleSvg />
                    <div className={s.subTitle}>
                        Strategic Business advisor, Former Minister of Tourism of the State
                        of Israel, Member of Parliament,
                        Olympic Judo Athlete
                    </div>
                    <div className={s.photoSE} />
                </div>
            );
        } else {
            return (
                <div className={s.MainMobileIphoneSEHorizontal}>
                    <TitleSvg />
                    <div className={s.subTitle}>
                        Strategic Business advisor, Former Minister of Tourism of the State
                        of Israel, Member of Parliament,
                        Olympic Judo Athlete
                    </div>
                    <div className={s.photoSE} />
                </div>
            );
        }
    }


    if (isIphoneProMaxVertical) {
        return (
            <>
                <div className={s.MainMobileIphoneProMaxVertical}>
                    <TitleSvg />
                    <div className={s.subTitle}>
                        Strategic Business advisor, Former Minister of Tourism of the State
                        of Israel, Member of Parliament,
                        Olympic Judo Athlete
                    </div>
                    <div className={s.photoProMax} />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className={s.MainMobileIphoneProMaxHorizontal}>
                    <TitleSvg />
                    <div className={s.subTitle}>
                        Strategic Business advisor, Former Minister of Tourism of the State
                        of Israel, Member of Parliament,
                        Olympic Judo Athlete
                    </div>
                    <div className={s.photoProMax} />
                </div>
            </>
        );
    }
};

const Main = (props) => {
    if(!props.isTabSelected && !isDesktop) return null

    return isMobile
        ? <MainMobile {...props} />
        : <MainTablet {...props} />
}

export default Main;