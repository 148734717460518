import React from 'react';
import s from "./News.module.scss";
import PreviewTabTitle from "../../components/PreviewTabTitle/PreviewTabTitle";
import {linksData} from "./linkData";
import {Scrollbars} from "react-custom-scrollbars-2";
import {isDesktop, TABS} from "../../App/App";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";

const NewsContent = () => (
    <div className={s.content}>
        <div className={s.tabTitle}>News</div>
        {linksData.map(news => (
            <div className={s.news}>
                <a rel="noreferrer"
                   target="_blank"
                   href={news.url}
                >
                    <img src={news.photo} alt="" />

                    <span>
                                {news.title}
                            </span>
                    <div className={s.divider} />
                    <div className={s.source}>
                        {news.source}
                    </div>
                </a></div>
        ))}
        <div className={s.copyright}>
            @ 2023. All Rights Reserved
        </div>
    </div>
)

const NewsDesktop = ({isTabSelected, tabName, onSelectTab}) => {
    if (!isTabSelected) {
        return (
            <div
                onClick={onSelectTab(tabName)}
                className={s.NewsDesktop}>
                <PreviewTabTitle title={'News'} />
            </div>
        )
    }

    return (
        <div className={`${s.NewsDesktop} ${isTabSelected && s.selectedDesktop} ${s.tableDesktop}`}>
            <Scrollbars className={s.scrollBar}>
                <NewsContent />
            </Scrollbars>
        </div>
    );
};

const NewsTablet = ({isTabSelected, tabName, onSelectTab, isShowCloseIcon}) => {
    if (!isTabSelected) {
        return (
            <div
                onClick={onSelectTab(tabName)}
                className={s.NewsTablet}>
                <PreviewTabTitle title={'News'} />
            </div>
        )
    }

    return (
        <div className={`${s.NewsTablet} ${isTabSelected && s.selectedTablet} ${s.tableTablet}`}>
            {isShowCloseIcon && <CloseIcon className={s.closeIcon} onClick={onSelectTab(TABS.MAIN)} />}
            <Scrollbars className={s.scrollBar}>
                <NewsContent />
            </Scrollbars>
        </div>
    );
};


const News = (props) => {
    if(props.selectedTab !== TABS.MAIN && !props.isTabSelected && !isDesktop) return null

    return isDesktop
        ? <NewsDesktop {...props} />
        : <NewsTablet {...props} />
}

export default News;