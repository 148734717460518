export const workHistory = [
    {
        upperTitle: '2023-current',
        title: 'CEO, STRATEGIC BUSINESS ADVISOR',
        text: 'Specializing in Business Development for Leading Companies in Israel and International Markets. ' +
            'Identifying opportunities for future business development and expansion.'
    }, {
        upperTitle: '2021-2023',
        title: 'MINISTER OF TOURISM',
        text: 'Member of the Ministerial committee for coping with coronavirus, Chair of joint intergovernmental committees with Azerbaijan, Kazakhstan, Uzbekistan, Turkmenistan, Moldova and Georgia.'
    }, {
        upperTitle: '2013-2023',
        title: 'MEMBER OF PARLIAMENT',
        text: 'Chair of the Committee for Immigration, Absorption and the diaspora, Member of the Committee for Foreign affairs and defense, Member of the Special Committee for coping with coronavirus and for state preparedness for pandemics and earthquakes.'
    }, {
        upperTitle: '2008-2012',
        title: 'MEMBER OF NETANYA CITY COUNCIL',
        text: 'Chair of the Board of the Municipal Construction Company, Head of sports and recreational\n' +
            'municipal portfolio.'
    }, {
        upperTitle: '1998-2011',
        title: 'MILITARY SERVICE',
        text: ''
    },
]

export const education = [
    {
        title: 'BA BUSINESS ADMINISTRATION, FINANCIAL MANAGEMENT',
        text: 'Reichman University, Herzliya'
    },
]

export const featured = [
    {
        title: 'Meeting the President of Azerbaijan',
        text: 'https://apa.az/az/resmi-xeber/prezident-ilham-eliyevisrailin-turizm-naziri-yoal-razvozovu-qebul-edib-yenilenib-video-696031',
        link: true,
    }, {
        title: 'Israeli companies always seek to work in Azerbaijan because there is trust - Minister Yoel Razvozov',
        text: 'https://www.trend.az/azerbaijan/politics/3578990.html',
        link: true
    }, {
        title: 'Interview to "Travel Weekly" magazine',
        text: 'https://www.travelweekly.com/On-TheRecord/Yoel-Razvozov-Israel-Minister-of-Tourism',
        link: true
    },
]